<template>
  <card type="chart">
    <template slot="header">
      <h5 class="card-category">HuskowLeBot - Firebase</h5>
      <h3 class="card-title mb-0">
        <div>
          <i class="tim-icons icon-bullet-list-67 text-primary "></i>
          Commands
        </div>
      </h3>
    </template>
    <div class="chart-area dasboard-api-content">
      <ul class="commands-list">
        <li class="d-flex justify-content-between">
          <div class="addCommand col-md-2" @click="showAddCommand()">
            <i class="tim-icons icon-simple-add"></i>
            ADD A COMMAND
          </div>
          <div class="addCommand" @click="getFirebaseApiCommands()">
            <i class="tim-icons icon-refresh-01 p-0 m-0"></i>
          </div>
        </li>
        <li
          v-for="command in firebaseCommands"
          :key="command.command"
          class="commands-items col-md-12"
        >
          <div class="getCommandFirebase col-md-2">
            {{ command.command }}
          </div>
          <div class="getDescriptionFirebase col-md-8 card-category">
            <p class="commandDescription">{{ command.message }}</p>
          </div>
          <div class="col-md-2 d-flex justify-content-end actionRoot">
            <div class="deleteCommand" @click="showCommandMofidy(command)">
              <i class="tim-icons icon-pencil"></i>
            </div>
            <div
              class="deleteCommand"
              @click="deleteFirebaseApiCommands(command.command)"
            >
              <i class="tim-icons icon-trash-simple"></i>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <CommandModifyPopup
      v-if="isCommandModifyOpen"
      :command-object="commandModifyData"
      @close="hideCommandModify"
    />
    <AddCommandPopup v-if="isAddCommandOpen" @close="hideAddCommand" />
  </card>
</template>
<script>
import CommandModifyPopup from "./CommandModifyPopup";
import AddCommandPopup from "./AddCommandPopup";
export default {
  components: { CommandModifyPopup, AddCommandPopup },
  data() {
    return {
      firebaseCommands: {},
      isCommandModifyOpen: false,
      isAddCommandOpen: false,
      commandModifyData: {}
    };
  },

  beforeMount() {
    this.getFirebaseApiCommands();
  },

  methods: {
    notifyVueDeleteCommand(command) {
      this.$notify({
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 0,
        message: `${command} command has been deleted.`
      });
    },
    notifyVueUpdateCommand() {
      this.$notify({
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 0,
        message: `Commands list has been updated.`
      });
    },
    async getFirebaseApiCommands() {
      const res = await fetch("https://cdn.teyz.fr:3000/get/commands");
      const data = await res.json();
      this.firebaseCommands = data.data.commands;
    },
    async deleteFirebaseApiCommands(command) {
      const res = await fetch(
        "https://cdn.teyz.fr:3000/delete/command/" + command
      );
      this.notifyVueDeleteCommand(command);
      this.getFirebaseApiCommands();
      this.updateFirebaseApiCommands();
    },
    showCommandMofidy(commandObject) {
      this.isCommandModifyOpen = true;
      this.commandModifyData = commandObject;
    },
    async updateFirebaseApiCommands() {
      fetch("https://cdn.teyz.fr:9090/update/commands")
        .then(res => res.json())
        .then(json => {
          if (json.status === "ok") {
            this.notifyVueUpdateCommand();
          }
        })
        .catch(error => {
          console.log("Error updateFirebaseApiCommands");
        });
    },

    showAddCommand() {
      this.isAddCommandOpen = true;
    },

    hideCommandModify() {
      this.getFirebaseApiCommands();
      this.updateFirebaseApiCommands();
      this.isCommandModifyOpen = false;
      this.commandModifyData = {};
    },

    hideAddCommand() {
      this.getFirebaseApiCommands();
      this.updateFirebaseApiCommands();
      this.isAddCommandOpen = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.addCommand {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px;
  margin-bottom: 16px;
  background-color: #fd5d93;
  border-radius: 0.2857rem;

  &:hover {
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
  }

  i {
    padding-right: 8px;
  }
}

.commandDescription {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
}
</style>
