<template>
  <div id="popupFrameRoot">
    <div class="popupRoot">
      <div class="popupContent"></div>
      <i
        @click="$emit('close')"
        class="tim-icons icon-simple-remove text-primary"
      ></i>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupFrame",

  beforeMount() {
    document.querySelector("body").style.overflow = "hidden";
  },

  beforeDestroy() {
    document.querySelector("body").style.overflow = "";
  }
};
</script>

<style lang="scss" scoped>
#popupFrameRoot {
  background-color: rgba($color: #27293d, $alpha: 0.75);
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;

  .popupRoot {
    background-color: #27293d;
    width: 500px;

    border-radius: 0.2857rem;

    -webkit-box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 16px;

    .popupHeader {
      .card {
        background: transparent !important;
        border: 0 !important;
        padding: 0px !important;
        box-shadow: none !important;

        .card-body {
          padding: 40px !important;
        }
      }
    }

    .icon-simple-remove {
      z-index: 10;
      transform: scale(1.5);
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
</style>
