<template>
  <PopupFrame @close="$emit('close')">
    <section>
      <div class="popupHeader">
        <card type="chart">
          <h3 class="card-title">
            <div>
              <i class="tim-icons icon-sound-wave text-primary "></i>
              Command : {{ commandObject.command }}
            </div>
          </h3>
          <base-input>
            <label>Description</label>
            <textarea
              rows="4"
              cols="80"
              class="form-control"
              :placeholder="commandObject.message"
              v-model="modifyCommand"
            >
            </textarea>
          </base-input>
          <div class="buttonContent">
            <button
              @click="
                updateFirebaseApiCommands(modifyCommand, commandObject.command)
              "
              class="btn saveButton btn-primary"
            >
              Save
            </button>
          </div>
        </card>
      </div>
    </section>
  </PopupFrame>
</template>

<script>
import PopupFrame from "@/components/PopupFrame";
export default {
  components: {
    PopupFrame
  },
  data() {
    return {
      modifyCommand: ""
    };
  },
  props: {
    commandObject: {
      type: Object,
      default: {}
    }
  },
  methods: {
    async updateFirebaseApiCommands(modifyCommand, command) {
      const res = await fetch(
        "https://cdn.teyz.fr:3000/update/command/" +
          command +
          "?message=" +
          modifyCommand
      );
      this.notifyVueUpdateCommand(command);
      this.$emit("close");
    },

    notifyVueUpdateCommand(command) {
      this.$notify({
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 0,
        message: `La commande ${command} à bien été modifiée.`
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  label {
    font-size: 1rem;
    margin-bottom: 0;
  }
}
.form-control {
  padding: 8px 0px 8px 0px !important;
  border-color: #e14eca;
  font-size: 1rem;
}

.card-chart {
  margin-bottom: 0 !important;
}

.card-footer {
  margin-top: 0 !important;
  padding: 0 15px !important;
  background-color: white !important;
}

.buttonContent {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  .saveButton {
    background: #e14eca;
    background-image: -webkit-gradient(
      linear,
      right top,
      left bottom,
      from(#e14eca),
      color-stop(#ba54f5),
      to(#e14eca)
    );
    background-image: linear-gradient(
      to bottom left,
      #e14eca,
      #ba54f5,
      #e14eca
    );
    background-size: 210% 210%;
    background-position: top right;
    background-color: #e14eca;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #ffffff;
  }
}
</style>
