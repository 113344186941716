<template>
  <div class="col-md-4 apiBox">
    <card type="chart">
      <template slot="header">
        <h5 class="card-category">{{ title }}</h5>
        <h3 class="card-title">
          <div>
            <i class="tim-icons icon-sound-wave text-primary "></i>
            Status
          </div>
          <div :class="['apiStatusOn', { apiStatusDone: apiStatus }]"></div>
        </h3>
      </template>
    </card>
  </div>
</template>
<script>
export default {
  data() {
    return { apiStatus: true };
  },
  props: {
    title: {
      type: String,
      default: {}
    },
    port: {
      type: String,
      default: 0
    }
  },

  beforeMount() {
    this.getApiStatus(this.port);
  },

  methods: {
    async getApiStatus(port) {
      const evtSource = new EventSource(
        "https://cdn.teyz.fr:" + port + "/get/status",
        {
          crossDomain: true
        }
      );

      evtSource.addEventListener("connected", e => {
        this.apiStatus = false;
      });

      evtSource.onerror = () => {
        this.apiStatus = true;
      };
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 640px) {
  .getDescription {
    font-size: 10px;
  }
}
</style>
