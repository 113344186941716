import { render, staticRenderFns } from "./ApiCardStatus.vue?vue&type=template&id=3897fdb5&scoped=true&"
import script from "./ApiCardStatus.vue?vue&type=script&lang=js&"
export * from "./ApiCardStatus.vue?vue&type=script&lang=js&"
import style0 from "./ApiCardStatus.vue?vue&type=style&index=0&id=3897fdb5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3897fdb5",
  null
  
)

export default component.exports