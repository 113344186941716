<template>
  <div class="LggRoot">
    <div class="row">
      <div class="col-md-12">
        <h1 class="mb-2">Huskow</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </div>
      <Divider />
    </div>
    <div class="row">
      <ApiCardStatus title="HuskowLeBot - Twitch" port="9090" />
      <ApiCardStatus title="HuskowLeBot - Firebase" port="3000" />
    </div>
    <CommandsFirebase />
    <ShowEndpoints />
  </div>
</template>
<script>
import ShowEndpoints from "./Huskow/ShowEndpoints";
import CommandsFirebase from "./Huskow/CommandsFirebase";
import ApiCardStatus from "./Dashboard/ApiCardStatus";
export default {
  components: { ShowEndpoints, CommandsFirebase, ApiCardStatus },
  data() {
    return {};
  },

  beforeMount() {},

  methods: {}
};
</script>
<style lang="scss" scoped></style>
