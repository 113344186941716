<template>
  <card class="card-login">
    <h1 slot="header" class="title text-danger">Sign-in</h1>
    <form @submit.prevent="loginUser">
      <div class="row">
        <div class="col-md-12 p-4">
          <base-input
            label="Username"
            placeholder="Username"
            v-model="login.username"
            required
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 p-4">
          <base-input
            label="Password"
            type="password"
            v-model="login.password"
            placeholder="************"
            required
          >
          </base-input>
        </div>
      </div>
      <button class="btn btn-primary text-center" type="submit">
        Sign in
      </button>
    </form>
  </card>
</template>
<script>
import store from "../../store";
export default {
  data() {
    return {
      login: {
        username: "",
        password: ""
      }
    };
  },
  methods: {
    async loginUser() {
      this.login.username = this.login.username.toLowerCase();
      const userIds = JSON.stringify(this.login);
      fetch("https://cdn.teyz.fr:4000/users/authenticate", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: userIds
      })
        .then(res => res.json())
        .then(json => {
          console.log(json);
          let id = json.id;
          let token = json.token;
          let user = {};
          //localStorage.setItem("jwt", token);
          if (token) {
            if (id === "60206e02b4592939cf1ae8b0") {
              user = {
                id: json.id,
                token: json.token,
                isHuskow: true,
                isBrakws: false
              };
            } else if (id === "6020444eb4592939cf1ae8af") {
              user = {
                id: json.id,
                token: json.token,
                isHuskow: true,
                isBrakws: true,
                isAdmin: true
              };
            } else if (id === "60207648b4592939cf1ae8b2") {
              user = {
                id: json.id,
                token: json.token,
                isHuskow: false,
                isBrakws: true
              };
            } else if (id === "60206e40b4592939cf1ae8b1") {
              user = {
                id: json.id,
                token: json.token,
                isHuskow: true,
                isBrakws: true,
                isAdmin: true
              };
            } else {
              user = {
                id: json.id,
                token: json.token,
                isHuskow: false,
                isBrakws: false,
                isAdmin: false
              };
            }
            this.$cookies.set("user", user);
            console.log(this.$cookies.get("user"));
            //store.getters.auth.loggedIn = true;
            this.$router.push("/dashboard");
          } else {
            this.notifyVueErrorLogin();
          }
        })
        .catch(error => {
          console.log(error);
        });
      this.resetInput();
    },

    resetInput() {
      this.login.username = "";
      this.login.password = "";
    },

    notifyVueErrorLogin() {
      this.$notify({
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 0,
        message: `Login failed - Please verify your informations`
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.card-login {
  margin-bottom: 0 !important;
  h1 {
    margin-top: 30px;
  }
  .form-group {
    text-align: left;
  }
}
</style>
