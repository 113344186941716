<template>
  <card type="chart">
    <template slot="header">
      <h5 class="card-category">HuskowLeBot - Twitch</h5>
      <h3 class="card-title mb-0">
        <div>
          <i class="tim-icons icon-bullet-list-67 text-primary "></i>
          Endpoints
        </div>
      </h3>
    </template>
    <div class="chart-area dasboard-api-content">
      <ul class="commands-list">
        <li class="commands-items">
          <div class="getCommand col-1">GET</div>
          <div class="getPath col-md-6">/get/score</div>
          <div class="getDescription col-md-5 card-category">
            Retrieves win - loose count
          </div>
        </li>
        <li class="commands-items">
          <div class="getCommand col-1 put">PUT</div>
          <div class="getPath col-md-6">/put/win</div>
          <div class="getDescription col-md-5 card-category">
            Add a win to win - loose count
          </div>
        </li>
        <li class="commands-items">
          <div class="getCommand col-1 put">PUT</div>
          <div class="getPath col-md-6">/put/loose</div>
          <div class="getDescription col-md-5 card-category">
            Add a loose to win - loose count
          </div>
        </li>
        <li class="commands-items">
          <div class="getCommand col-1 update">UPDATE</div>
          <div class="getPath col-md-6">/update/win</div>
          <div class="getDescription col-md-5 card-category">
            Remove a win to win - loose count
          </div>
        </li>
        <li class="commands-items">
          <div class="getCommand col-1 update">UPDATE</div>
          <div class="getPath col-md-6 col-xs-4">/update/loose</div>
          <div class="getDescription col-md-5 card-category">
            Remove a loose to win - loose count
          </div>
        </li>
        <li class="commands-items">
          <div class="getCommand col-1 delete">DELETE</div>
          <div class="getPath col-md-6">/delete/score</div>
          <div class="getDescription col-md-5 card-category">
            Reset win - loose count
          </div>
        </li>
      </ul>
    </div>
  </card>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped></style>
