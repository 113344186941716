<template>
  <div class="wrapper wrapper-full-page section content">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-4 text-center">
          <LoginForm />
        </div>
        <div class="col-md-4">
          <img src="/img/pale-astronaut-in-space.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "./Login/LoginForm";
export default {
  components: {
    LoginForm
  }
};
</script>
<style lang="scss" scoped>
.wrapper-full-page {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
