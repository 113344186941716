import { render, staticRenderFns } from "./AddCommandPopup.vue?vue&type=template&id=47d061d0&scoped=true&"
import script from "./AddCommandPopup.vue?vue&type=script&lang=js&"
export * from "./AddCommandPopup.vue?vue&type=script&lang=js&"
import style0 from "./AddCommandPopup.vue?vue&type=style&index=0&id=47d061d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d061d0",
  null
  
)

export default component.exports